/*
Font: 		Loubag
Style: 		Bold
URL: 		https://www.youworkforthem.com/font/T12784/loubag
Foundry: 	Kadek Mahardika
Foundry: 	https://www.youworkforthem.com/designer/1418/kadek-mahardika
Copyright:	Copyright Â© 2020 by Kadek Mahardika. All rights reserved.
Version: 	17
Created:	August 30, 2020
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
	font-family: 'Loubag-Bold';
	src: url('../../assets/loubag-bold/loubag-bold.eot');
	src: url('../../assets/loubag-bold/loubag-bold.eot?#iefix') format('embedded-opentype'),
             url('../../assets/loubag-bold/loubag-bold.woff2') format('woff2'),
	     url('../../assets/loubag-bold/loubag-bold.woff') format('woff'),
	     url('../../assets/loubag-bold/loubag-bold.ttf') format('truetype'),
	     url('../../assets/loubag-bold/loubag-bold.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}


.jumbotron-fluid {
    background: linear-gradient(0.25turn, #FB9ED5, #FFFFFF, #D5E2D3);
    padding-left: 6em;
    padding-right: 6em;
    font-family: "Montserrat";
    height: 70vh;
}

h1 {
    font-family: 'Loubag-Bold';
    padding-top: 10rem;
    color: #0F320C;
}

.mission {
    width: 80%;
    font-size: 1rem;
}

.btn {
    font-family: "Montserrat", sans-serif;
    background-color:#F57FC4;
    box-shadow: 3px 6px #0F320C;
    margin: 2rem;
    margin-bottom: 6rem;
}

.btn:hover {
    background-color: #233F14;
    color: white;
    box-shadow: 3px 6px;
}

@media only screen and (max-width: 450px) {
    .mission {
        font-size: 0.7rem;
        width: 100%;
        line-height: 1.3;
    }

    h1 {
        font-size: 1.4rem;
        padding-top: 3rem;
    }

    .btn {
        font-size: 0.6rem;
    }
}


