.horizontal-card {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
    height: 20vh;
}

.horizontal-card:hover {
    transform: scale(1.05);
}

.card-content {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.card-image {
    flex: 1;
}

.card-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.card-body {
    flex: 4;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.card-title {
    font-family: "Loubag-Regular", "League Spartan", sans-serif;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: -0.5px;
    line-height: 1.5rem;
    margin: 0;
}

.card-text {
    font-family: "Montserrat";
    font-size: 0.8rem;
    line-height: 0.9rem;
    margin-top: 0.2rem;
}

@media only screen and (max-width: 900px) {
    .horizontal-card {
        height: 9rem;
    }

    .card-text {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 600px) {
    .horizontal-card {
        height: 14vh;
    }

    .card-text {
        font-size: 0.5rem;
        line-height: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .card-image {
        flex: 2;
    }

    .card-title {
        font-size: 0.7rem;
        line-height: 0.8rem;
    }
}

@media only screen and (max-width: 400px) {
    .horizontal-card {
        height: 17vh;
    }

    .card-text {
        font-size: 0.45rem;
        line-height: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .card-title {
        margin-top: 0.5rem;
        font-size: 0.7rem;
        line-height: 0.8rem;
    }
}
