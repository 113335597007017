@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

footer {
    background-color: #E3F0E4;
    font-family: "Libre Baskerville";
}

.footer-section {
    text-align: center;
    font-size: 1rem;
    padding-right: 1.5rem;
    section {
        list-style-type: none;
        font-size: 1em;
        font-family: "Montserrat", sans-serif;
    }
}

a {
    text-decoration: none;
    color: black;
}

section a:hover {
    text-decoration: underline;
    color: black;
}

footer img {
    box-shadow: 1px 0px 4px 4px #FB9ED5;
}

.logo {
    padding-right: 1rem;
}
.logo p {
    font-size: 1rem;
}

@media only screen and (max-width: 450px) {
    .footer-section {
        text-align: center;
        font-size: 0.9rem;
        padding-right: 1rem;
        section {
            list-style-type: none;
            font-size: 0.8em;
            font-family: "Montserrat", sans-serif;
        }
    }
}
